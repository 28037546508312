$accent-colors: (
  shine-orange: hsl(28, 86%, 50%),
  shine-gold: hsl(40, 59%, 60%),
  shine-red: hsl(344, 97%, 39%),
  shine-blue: hsl(243, 100%, 45%),
  shine-black: hsl(0, 0%, 0%),
  social-blue: hsl(211, 92%, 54%),
  td-blue: hsl(203, 56%, 27%),
  td-teal: hsl(179, 45%, 64%),
  ef-green: hsl(152, 41%, 52%),
  ef-green-dark: hsl(152, 80%, 29%),
  brm-green: hsl(140, 49%, 66%),
  jre-royal-blue: hsl(218, 100%, 47%),
  innovo-yellow: hsl(50, 100%, 50%),
  famfluence-brown: hsl(33, 32%, 62%),
  tpp-blue: rgb(37, 135, 187),
  tpp-dark-blue: hsl(207, 100%, 15%),
  oneds-pink: rgb(254, 1, 254),
  gb-sandy: rgb(244, 247, 242),
  gb-tan: rgb(217, 208, 199),
  gb-beige: hsl(26, 32%, 76%),
  hn-red: hsl(357, 75%, 51%),
  mn-yellow: hsl(46, 96%, 63%),
  special-lavender: rgb(201, 137, 255),
  onyx-pink: rgb(204, 46, 114),
  stetts-pink: rgb(255, 106, 198),
  mediary-pink: rgb(255, 16, 240),
  bicoastal-blue: rgb(43, 184, 204),
  plum: hsl(333, 70%, 16%),
  pastel-yellow: hsl(50, 83%, 64%),
  willy-blue: #8bc4da,
  gaybor-green: #8dd2a6,
  farq-green: #0c5563,
  rebellion-pink: rgb(253, 108, 142),
  rock-red: hsl(360, 77%, 39%),
  rave-purple: #8778f7,
  royal-blue: #4169e1,
);

$other-colors: (
  sandy: hsl(30, 19%, 82%),
  sandy-lighter: hsl(12, 25%, 92%),
  xanadu: rgb(121, 132, 120),
  cultured: hsl(210, 14%, 95%),
  deep-space-sparkle: #4d6a6dff,
  tuscan-red: #885053ff,
  caput-mortuum: #5a2328ff,
  oxford-blue: #0e1c36,
  flax: #e3d87eff,
  pastel-orange: rgb(232, 145, 135),
  shine-gold-light: hsl(41, 57%, 81%),
  dark-blue: hsl(230, 40%, 14%),
  timberwolf: hsl(70, 12%, 81%),
  egyptian-blue: rgb(10, 54, 157),
  han-blue: #4472caff,
  glaucous: #5e7ce2ff,
  light-gray: #afafaf,
  dark-purple: #351f36,
  lighter-purple: rgb(116, 75, 118),
  maryland-gold: #ffd200,
  maryland-red: #e21833,
  maryland-red-dark: #951022,
  custom-danger: hsl(351, 67%, 44%),
  gbb-cream: hsl(72, 20%, 95%),
  dim-gray: rgb(105, 105, 105),
  white: #fff,
  gray-100: #f8f9fa,
  gray-200: #e9ecef,
  gray-300: #dee2e6,
  gray-400: #ced4da,
  gray-500: #adb5bd,
  gray-600: #6c757d,
  gray-700: #495057,
  gray-800: #343a40,
  gray-900: #212529,
  black: #000,
);

$colors: map-merge($accent-colors, $other-colors);

// Export the list of colors
:export {
  @each $name, $color in $colors {
    #{"" + $name}: $color;
  }
}

@function get-color($color) {
  @return map-get($colors, $color);
}

$primary: get-color(tpp-dark-blue);
$btn-primary-color: white;

// $secondary: #8f5325;
$secondary: get-color(oxford-blue);
$success: #3e8d63;
$info: get-color(tpp-blue);
$warning: get-color(flax);
$danger: get-color(caput-mortuum);
$light: get-color(cultured);
$dark: get-color(tpp-dark-blue);

/*! `Custom` Bootstrap 5 theme */
// @import url(https://use.fontawesome.com/releases/v5.0.10/css/all.css);
@import url('https://fonts.googleapis.com/css2?family=Lora:wght@500&family=Open+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
$headings-font-family: 'Open Sans';

// $enable-grid-classes:false;
// $primary:#0a369d;
// $secondary:#798478;
// $success:#035668;
// $danger:#FF444F;
// $info:#3F81C7;
// $warning:#0DE2EA;
// $light:#eAeAeA;
// $dark:#223322;
// $enable-gradients:false;
// $spacer:1rem;
// $border-width:1px;
// $btn-border-radius:.25rem;
// @import "bootstrap";

// Add SASS theme customizations here..

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
);

:root {
  --maryland-red-dark: get-color(maryland-red-dark);
}
